



























































import { Component } from 'vue-property-decorator';
import BaseToolbar from '@/components/core/BaseToolbar.vue';
import TeacherSubmissionsToolbar from './TeacherSubmissionsToolbar.vue';

@Component({
  components: {
    BaseToolbar
  }
})
export default class TeacherSubmissionsBottomToolbar extends TeacherSubmissionsToolbar {
}
