var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.isEmbedded && !_vm.selectedTeacher
    ? _c(
        "v-toolbar",
        { staticStyle: { "z-index": "1" } },
        [
          _c(
            "v-row",
            {
              staticClass: "text-center justify-space-between",
              attrs: { elevation: "1", outlined: "" },
            },
            [
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "auto" } },
                [
                  _vm.hasData
                    ? _c(
                        "v-menu",
                        {
                          attrs: {
                            bottom: "",
                            "offset-y": "",
                            "close-on-click": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on: onMenu }) {
                                  return [
                                    _c(
                                      "pb-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            color: "primary",
                                            label: _vm.$t("actionsLabel"),
                                            icon: "",
                                          },
                                        },
                                        { ...onMenu }
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            "fal fa-ellipsis-stroke-vertical"
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1297348514
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              !_vm.selectedWeek &&
                              !_vm.selectedTeacher &&
                              _vm.hasData
                                ? _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "pb-btn",
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: { click: _vm.showWeek },
                                        },
                                        [
                                          _c("span", { staticClass: "ml-1" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("viewByWeek"))
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.selectedWeek
                                ? _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "pb-btn",
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              _vm.selectedWeek = null
                                            },
                                          },
                                        },
                                        [
                                          _c("span", { staticClass: "ml-1" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("allWeeksLabel"))
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              (!_vm.selectedTeacher && !_vm.selectedWeek && !_vm.yearPassed) ||
              _vm.selectedWeek
                ? _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-menu",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on: onMenu }) {
                                  return [
                                    _c(
                                      "pb-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            color: "primary",
                                            label: _vm.$t(
                                              "messageTeachersLabel"
                                            ),
                                            icon: "",
                                          },
                                        },
                                        { ...onMenu }
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("fal fa-message"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3911804291
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.openMessageEditor(true)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(_vm.$t("completeLabel"))),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.openMessageEditor(false)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(_vm.$t("unsubmittedlabel"))
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }